<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Send Messsage</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="msg_class"
            >
              <v-select
                ref="msg_class"
                multiple
                :closeOnSelect="false"
                v-model="msgClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillMsgSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="msgClass[0] != 0">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="msg_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="msgSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="msgSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkMsgSections()"
                ref="msg_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="msgObj.dt"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="msg_status"
            >
              <v-select
                ref="msg_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="msgObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveMessage()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Send Message</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Reports</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="rep_class"
            >
              <v-select
                ref="rep_class"
                multiple
                :closeOnSelect="false"
                v-model="repClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillRepSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="repClass[0] != 0">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="rep_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="repSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="repSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkRepSections()"
                ref="rep_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="rep_date"
            >
              <flat-pickr
                ref="rep_date"
                :config="config2"
                v-model="repDate"
                class="form-control"
                placeholder="Select Date"
                @on-change="setRepDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="rep_status"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                ref="rep_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="repObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
                @input="setStatus()"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getReport()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Show</span>
            </b-button>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getSheet()"
              :disabled="sheetLoading"
              block
            >
              <b-spinner v-if="sheetLoading" small type="grow" />
              <span v-else>Attendance Sheet</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Smart Attendance</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="smartObj.dt"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Gr Numbers"
              invalid-feedback="Please enter gr separated by space"
              ref="grs"
            >
              <b-form-textarea
                ref="grs"
                placeholder="Enter gr numbers seperated by space"
                v-model="smartObj.gr"
                rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Status"
              invalid-feedback="Status is required."
              ref="sm_status"
            >
              <v-select
                ref="sm_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="smartObj.status"
                :options="smartOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveSmart()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <!-- Details -->
    <b-row v-if="showreport">
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="editAtt"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Edit Attendace</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideAtt()"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="pt-1 pb-1" style="align-items: center">
            <b-col md="3">
              <b-img
                v-if="attendance.picture == null || attendance.picture == ''"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                style="width: 100px; height: 100px; object-fit: cover"
              />
              <b-img
                v-else
                :src="attendance.picture"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
              />
            </b-col>
            <b-col md="8" class="ml-1">
              <h5>Name: {{ attendance.name }}</h5>
              <h5>Father Name: {{ attendance.father_name }}</h5>
              <h5>Gr# {{ attendance.grNo }}</h5>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group
                label="Time"
                invalid-feedback="time is required."
                ref="time"
              >
                <flat-pickr
                  v-model="timePicker"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i:K',
                  }"
                  @input="setTime()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Date"
                invalid-feedback="date is required."
                ref="date"
              >
                <flat-pickr
                  ref="date"
                  :config="config2"
                  v-model="datepickr"
                  class="form-control"
                  placeholder="Select Date."
                  @input="setTime()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group
                label="Select Status"
                invalid-feedback="status is required."
                ref="status"
              >
                <v-select
                  ref="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  v-model="attendance.obj.status"
                  :options="statusOptions"
                  :reduce="(opt) => opt.value"
                  label="name"
                  placeholder="select status"
                />
              </b-form-group>
            </b-col>
            <b-col class="mt-1" md="12">
              <b-button
                variant="success"
                class="mr-1 fullWidth"
                @click="saveAttendance()"
                :disabled="request"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> Save </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-col>
        <div class="d-flex justify-content-between">
          <b-button
            variant="outline-secondary"
            class="mr-50"
            @click="showreport = false"
          >
            <feather-icon class="" icon="ArrowLeftIcon" />
            Back
          </b-button>
          <!-- <div>
            <b-button
              class="mr-50"
              v-for="t in types"
              :key="type"
              :variant="t.value == report.type ? 'primary' : 'outline-primary'"
              @click="setType(t.value)"
            >
              {{ t.name }}
            </b-button>
          </div> -->
        </div>
        <b-card class="mt-1">
          <b-row style="align-items: center">
            <!-- <b-col md="2">
              <b-form-group
                label="Select type"
                invalid-feedback="type is required."
                ref="type"
              >
                <v-select
                  ref="type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  v-model="report.type"
                  :options="types"
                  :reduce="(opt) => opt.value"
                  label="name"
                  @input="setType()"
                  placeholder="select type"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="2" v-if="showClasses">
              <b-form-group
                label="Select Class"
                invalid-feedback="class is required."
                ref="class"
              >
                <v-select
                  ref="class"
                  multiple
                  :closeOnSelect="false"
                  v-model="classID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classesOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  @input="FillSections()"
                  placeholder="Select class"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" v-if="showClasses">
              <b-form-group
                label="Select Section"
                invalid-feedback="section is required."
                ref="section"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="secID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="admittedSections"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  @input="checkSections()"
                  ref="section"
                  label="section"
                  placeholder="Select section"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Date"
                invalid-feedback=" date is required."
                ref="date"
              >
                <flat-pickr
                  ref="date"
                  :config="config"
                  v-model="report.dtFrom"
                  class="form-control"
                  placeholder="Select Date."
                />
                <!-- @input="setDate()" -->
              </b-form-group>
            </b-col>
            <b-button
              class="ml-1"
              :disabled="gridLoading"
              variant="primary"
              @click="loadgrid()"
            >
              Show
            </b-button>
            <b-button
              variant="success"
              class="ml-1"
              :disabled="saving"
              @click="save()"
            >
              Save
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Smart Attendance"
              @click="showSmart()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Reports"
              @click="openReport()"
            >
              <feather-icon icon="BarChart2Icon" />
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Messages"
              @click="openMessage()"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </b-row>
          <b-row class="mt-1" v-if="showGrid">
            <b-col lg="10" md="9">
              <b-form-group label="Search">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Search by name or gr"
                />
              </b-form-group>
            </b-col>
            <b-col lg="2" md="3">
              <b-form-group label="Sort By">
                <v-select
                  v-model="sortBy"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  :reduce="(val) => val.value"
                  label="text"
                  @input="sortData()"
                  :clearable="false"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-table
                class="mt-1"
                :busy="gridLoading"
                show-empty
                :items="filteredAtt"
                :fields="fields"
                responsive
                hover
              >
                <template #table-busy>
                  <div
                    class="d-flex justify-content-center mb-3"
                    style="margin-top: 50px"
                  >
                    <b-spinner
                      style="width: 3rem; height: 3rem"
                      type="grow"
                      variant="primary"
                      label="Spinning"
                    ></b-spinner>
                  </div>
                </template>

                <template #cell(cls)="data">
                  <b-badge style="margin-inline: 2px" variant="light-primary">
                    {{ data.item.cls }}
                  </b-badge>
                </template>

                <!-- <template #cell(profile)="data">
                  <b-avatar
                    size="36"
                    class=""
                    variant="primary"
                    :src="data.item.picture"
                  />
                </template> -->
                <template #cell(date)="data">
                  <b-badge variant="light-primary">
                    {{
                      new Date(data.item.date).toLocaleDateString("en-UK", {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      })
                    }}
                  </b-badge>
                  <b-badge variant="light-primary">
                    {{
                      new Intl.DateTimeFormat("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(data.item.date))
                    }}
                  </b-badge>
                </template>
                <template #cell(status)="data">
                  <div class="d-flex align-items-center">
                    <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'P'
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="changeAtt(data.index, 'P')"
                    >
                      P
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'A'
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="changeAtt(data.index, 'A')"
                    >
                      A
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'L' ? 'info' : 'outline-info'
                      "
                      @click="changeAtt(data.index, 'L')"
                    >
                      L
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status.toLowerCase() == 'late'
                          ? 'warning'
                          : 'outline-warning'
                      "
                      @click="changeAtt(data.index, 'Late')"
                    >
                      Late
                    </b-button>
                    <!-- <b-button
                      pill
                      size="sm"
                      style="margin-inline: 2px"
                      :variant="
                        data.item.obj.status == 'H' ? 'info' : 'outline-info'
                      "
                      @click="changeAtt(data.index, 'H')"
                    >
                      H
                    </b-button> -->
                  </div>
                  <!-- <b-badge v-if="data.item.status == 'P'" variant="light-success">
                  {{ data.item.status }}
                </b-badge>
                <b-badge
                  v-else-if="data.item.status == 'L'"
                  variant="light-warning"
                >
                  {{ data.item.status }}
                </b-badge>
                <b-badge v-else variant="light-danger">
                  {{ data.item.status }}
                </b-badge> -->
                </template>
                <template #cell(actions)="data">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click="Edit(data.item)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- attendance card -->
    <b-row align-h="center" v-else-if="dataLoading">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <div v-else>
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Settings</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideSideBar()"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="pt-1 pb-1">
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Barcode</h4>
              <b-form-checkbox
                v-model="settings.attendanceMode"
                switch
                value="barcode"
                unchecked-value=""
              />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Notification on arrival</h4>
              <b-form-checkbox v-model="settings.notifyArrival" switch />
            </b-col>
            <!-- <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Notification on departure</h4>
              <b-form-checkbox v-model="settings.notifyDeparture" switch />
            </b-col> -->
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Show dues</h4>
              <b-form-checkbox v-model="settings.showDues" switch />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Show recent attendance</h4>
              <b-form-checkbox v-model="settings.showRecent" switch />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Auto hide</h4>
              <b-form-checkbox v-model="settings.autoHide" switch />
            </b-col>
            <b-col v-if="settings.autoHide" md="12">
              <h4 class="mr-1">
                Hide After <span style="font-size: 14px">(seconds)</span>
              </h4>
              <b-form-input
                id="mc-first-name"
                type="number"
                placeholder=""
                ref="autosec"
                v-model="settings.hideAfterSeconds"
              />
            </b-col>
            <b-col class="mt-1" md="12">
              <b-button
                variant="success"
                class="mr-1 fullWidth"
                @click="saveSettings()"
                :disabled="request"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> Save </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-row align-h="center">
        <b-col v-if="studentLoading" md="4">
          <b-card class="text-center p-1" style="position: relative">
            <b-spinner
              class="m-5"
              style="width: 4rem; height: 4rem"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </b-card>
        </b-col>
        <b-col lg="4" md="5" v-else>
          <b-card
            class="text-center p-1"
            style="position: relative"
            :class="{ 'set-border': currentStd.dues > 0 }"
          >
            <div
              style="position: absolute; right: 15px; top: 15px; display: none"
            >
              <!-- style="position: absolute; right: 15px; top: 15px" -->
              <feather-icon
                class="cursor-pointer"
                icon="SettingsIcon"
                size="22"
                v-b-tooltip.hover.right
                title="Settings"
                @click="showSettings()"
              />
              <br />
              <template v-if="syncObj && syncObj.valueBool">
                <b-spinner
                  v-if="syncing"
                  class="mt-2"
                  variant="primary"
                  label="Spinning"
                  style="width: 22px; height: 22px"
                ></b-spinner>
                <feather-icon
                  v-else
                  class="cursor-pointer mt-2"
                  icon="RefreshCcwIcon"
                  size="22"
                  v-b-tooltip.hover.right
                  title="Sync Attendance"
                  :badge="syncData.length"
                  @click="syncAtt()"
                />
              </template>
            </div>
            <template v-if="currentStaff">
              <b-img
                v-if="currentStaff.picture"
                class="round mb-1"
                :src="currentStaff.picture"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                :class="{ 'set-picSize': picObj && picObj.valueBool }"
              />
              <b-img
                v-else
                class="round mb-1"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                :class="{ 'set-picSize': picObj && picObj.valueBool }"
              />
              <h3 v-if="currentStaff.name" class="mb-1">
                {{ currentStaff.name }}
              </h3>
              <h4 v-if="currentStaff.designation">
                {{ currentStaff.designation }}
              </h4>
              <h4 v-else>-</h4>
              <h1 v-if="currentStaff.time" class="mt-1">
                {{
                  new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(currentStaff.time))
                }}
              </h1>
            </template>

            <template v-else>
              <b-img
                v-if="
                  currentStd.std.picture == '' || currentStd.std.picture == null
                "
                class="round mb-1"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                :class="{ 'set-picSize': picObj && picObj.valueBool }"
              />
              <b-img
                v-else
                class="round mb-1"
                :src="currentStd.std.picture"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                :class="{ 'set-picSize': picObj && picObj.valueBool }"
              />
              <h3 v-if="currentStd.std.name" class="mb-1">
                {{ currentStd.std.name }}
              </h3>
              <h3 v-else class="mb-1">-</h3>
              <h4 v-if="currentStd.std.cls" style="margin-bottom: 0.5rem">
                {{ currentStd.std.cls }}
              </h4>
              <h4 v-else style="margin-bottom: 0.5rem">-</h4>
              <h4 style="font-weight: 400; margin-bottom: 0.5rem">
                GR #
                <span v-if="currentStd.std.grNo">{{
                  currentStd.std.grNo
                }}</span>
                <span v-else>-</span>
              </h4>
              <h4
                v-if="settings.showDues"
                style="font-weight: 400; margin-bottom: 0.5rem"
              >
                Dues: <span v-if="currentStd.dues">{{ currentStd.dues }}</span>
                <span v-else>-</span>
              </h4>
              <h1 v-if="currentStd.time" class="mt-1">
                {{
                  new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(currentStd.time))
                }}
              </h1>
            </template>

            <!-- <h4 class="text-danger font-weight-bolder">dues</h4> -->
            <b-row align-h="center">
              <b-form-input
                class="mt-1"
                style="width: 80%"
                id="mc-first-name"
                placeholder="Scan here"
                ref="name"
                autofocus
                v-model="studentID"
                @keyup.enter="GetStudent()"
              />
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="1" md="2" class="d-flex flex-wrap d-md-block">
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Settings'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="SettingsIcon"
              size="22"
              @click="showSettings()"
            />
          </b-card>
          <b-card
            v-if="syncObj && syncObj.valueBool"
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Sync Attendance'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <b-spinner
              v-if="syncing"
              class=""
              variant="primary"
              label="Spinning"
              style="width: 22px; height: 22px"
            ></b-spinner>
            <feather-icon
              v-else
              class="cursor-pointer"
              icon="RefreshCcwIcon"
              size="22"
              :badge="syncData.length + staffData.length"
              @click="syncAtt()"
            />
          </b-card>
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Smart Attendance'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="CheckCircleIcon"
              size="22"
              @click="showSmart()"
            />
          </b-card>
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Reports'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="BarChart2Icon"
              size="22"
              @click="openReport()"
            />
          </b-card>
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Messages'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="MailIcon"
              size="22"
              @click="openMessage()"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col lg="4" md="5">
          <b-button variant="primary" block @click="openDetails()">
            Show Details
          </b-button>
        </b-col>
        <b-col lg="1" md="2"></b-col>
      </b-row>
      <b-row v-if="this.settings.showRecent && this.filteredItems.length > 0">
        <b-col md="12">
          <h3>Recent Attendace</h3>
        </b-col>
        <b-col md="3" v-for="card in filteredItems" :key="card">
          <b-card :class="{ 'set-border': card.dues > 0 }">
            <b-row v-if="card.isStaff">
              <b-col md="3">
                <b-avatar
                  v-if="card.picture == '' || card.picture == null"
                  size="48"
                  src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                />
                <b-avatar v-else size="48" :src="card.picture" />
              </b-col>
              <b-col md="9">
                <h5>{{ card.name }}</h5>
                <h5>
                  {{ card.designation ? card.designation : "-" }}
                </h5>
                <h5>
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(card.time))
                  }}
                </h5>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col md="3">
                <b-avatar
                  v-if="card.std.picture == '' || card.std.picture == null"
                  size="48"
                  src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                />
                <b-avatar v-else size="48" :src="card.std.picture" />
              </b-col>
              <b-col md="9">
                <h5>{{ card.std.name }}</h5>
                <h5>
                  {{ card.std.cls }}
                  <span class="ml-50">GR# {{ card.std.grNo }}</span>
                </h5>
                <h5>
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(card.time))
                  }}
                  <span v-if="settings.showDues" class="ml-50"
                    >Dues: {{ card.dues }}</span
                  >
                </h5>
              </b-col>
            </b-row>
            <!-- variant="transparent" -->
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BAvatar,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BModal,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BCard,
    BRow,
    BImg,
    BAvatar,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BModal,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // let right = {};
    // this.$store.state.menu.forEach((el) => {
    //   el.children.forEach((ch) => {
    //     right[ch.route] = {
    //       view: ch.view,
    //       add: ch.add,
    //       edit: ch.edit,
    //       delete: ch.delete,
    //     };
    //   });
    // });
    // // console.log(right);
    // this.$store.commit("setRights", right);

    // if (!this.$store.state.rights[this.$route.name]) {
    //   // console.log(this.$store.state.rights[this.$route.name]);
    //   this.$router.replace({
    //     name: "misc-not-authorized",
    //   });
    // } else {
    //   this.rights = this.$store.state.rights[this.$route.name];
    //   this.LoadClasses();
    //   this.LoadSettings();
    //   this.loadSessions();
    //   this.loadOther();
    // }
    this.LoadClasses();
    this.LoadSettings();
    this.loadSessions();
    this.loadOther();
  },
  beforeDestroy() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filteredItems: function () {
      return this.items.slice(0, 12);
    },
    filteredAtt() {
      return this.gridData.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.grNo.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {},
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        // mode: "range",
      },
      repDate: "",
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      myDepartments: [],
      request: false,
      sheetLoading: false,
      attendance: {
        picture: null,
        obj: {
          status: null,
        },
      },
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      studentLoading: false,
      visibility: false,
      visibility2: false,
      visibility3: false,
      visibility4: false,
      logoloading: "",
      searchQuery: "",
      sortOptions: [
        { text: "Name", value: "name" },
        { text: "GR#", value: "gr" },
      ],
      sortBy: this.$store.state.attSort,
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      report: {
        type: "",
        dtFrom: new Date().toJSON().split("T")[0],
        // dtTo: null,
        clsIDs: [],
        secIDs: [],
      },
      editAtt: false,
      showreport: false,
      rangeDate: null,
      types: [
        { name: "Student", value: "student" },
        { name: "Staff", value: "staff" },
      ],
      statusOptions: [
        { name: "All", value: "" },
        { name: "Present", value: "P" },
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      smartOptions: [
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      showClasses: false,
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      admittedSections: [{ section: "All Sections", id: 0 }],

      classID: [0],
      secID: [0],

      gridData: [],
      showGrid: false,
      gridLoading: false,
      fields: [
        // { label: "profile", key: "profile" },
        { label: "gr no", key: "grNo" },
        { label: "name", key: "name" },
        { label: "Father name", key: "father_name" },
        { label: "class", key: "cls" },
        // { label: "date", key: "date" },
        { label: "status", key: "status" },
        // { key: "actions", label: "actions" },
      ],

      errors: {
        status: false,
      },

      index: null,
      items: [],
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],

      secText: "Section",
      classText: "Class",
      selected: null,
      myObj: {
        picture: null,
        cls: null,
        grNo: null,
        name: null,
      },
      currentStd: {
        std: {
          picture: null,
          cls: null,
          grNo: null,
          name: null,
        },
      },
      settings: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        notifyArrival: false,
        notifyDeparture: false,
        showDues: false,
        showRecent: false,
        autoHide: false,
        hideAfterSeconds: 5,
        attendanceMode: "",
      },
      msgObj: {
        dt: "",
        clsIDs: [0],
        secIDs: [0],
        status: "",
      },
      msgSecOptions: [{ section: "All Sections", id: 0 }],
      msgClass: [0],
      msgSection: [0],

      repObj: {
        type: "",
        dtFrom: "",
        clsIDs: [0],
        secIDs: [0],
        status: [],
      },
      repSecOptions: [{ section: "All Sections", id: 0 }],
      repClass: [0],
      repSection: [0],

      detailObj: {},
      timeout: 10000,
      studentID: null,
      saving: false,

      smartObj: {
        status: "",
        dt: new Date(),
        gr: "",
      },
      sessions: [],
      sessionID: 0,
      picObj: null,
      syncObj: {
        valueBool: true,
      },
      allStudents: [],
      syncing: false,
      syncData: this.$store.state.syncList,
      intervalId: null,
      allStaff: [],
      staffData: this.$store.state.staffSyncList,
      currentStaff: null,
      // {
      //   id: 0,
      //   name: "",
      //   picture: null,
      //   designation: "",
      // },
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    sortData() {
      this.$store.commit("setAttSort", this.sortBy);

      if (this.sortBy == "gr") {
        this.gridData = this.gridData.sort((a, b) =>
          a.grNo
            .toLowerCase()
            .localeCompare(b.grNo.toLowerCase(), "en", { numeric: true })
        );
      } else {
        this.gridData = this.gridData.sort((a, b) =>
          a.name
            .toLowerCase()
            .localeCompare(b.name.toLowerCase(), "en", { numeric: true })
        );
      }
    },

    async loadOther() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Settings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      this.picObj = res.find((el) => el.key == "picture_size");
      // this.syncObj = res.find((el) => el.key == "attendance_sync");
      // console.log(this.syncObj);
      if (this.syncObj && this.syncObj.valueBool) {
        this.loadAll();
        this.loadAllStaff();
      } else {
        this.$store.commit("setSyncList", []);
        this.$store.commit("setStaffSyncList", []);
        this.syncData = [];
        this.staffData = [];
        this.dataLoading = false;
      }
    },
    async loadAll() {
      var obj = {
        url:
          this.$store.state.domain +
          "Attendance/LoadAllStudents?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allStudents = await this.get(obj);
      // console.log(this.allStudents);
      this.intervalId = setInterval(this.syncAtt, 31000);
      // console.log(this.intervalId);

      this.dataLoading = false;
      // this.allStudents = [
      //   {
      //     std: {
      //       id: 10159,
      //       name: "Abdul",
      //       father_name: "Basit",
      //       grNo: "11225606",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10036,
      //       name: "Abdul Basit",
      //       father_name: "-",
      //       grNo: "300",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678080163243-824907960.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 20,
      //       name: "Ahmed",
      //       father_name: "Iqbal",
      //       grNo: "95",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674048088395-571856006.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "XII-Sci-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 16,
      //       name: "Ahmed",
      //       father_name: "Ali",
      //       grNo: "5",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674047755554-411770904.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10067,
      //       name: "Ahmed",
      //       father_name: "Akbar",
      //       grNo: "12365",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10066,
      //       name: "Ahmed",
      //       father_name: "Arshad",
      //       grNo: "12352",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10062,
      //       name: "Ahmed",
      //       father_name: "Ali",
      //       grNo: "11334",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10054,
      //       name: "Ahmed",
      //       father_name: "Ali",
      //       grNo: "11223344",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10053,
      //       name: "Ahmed",
      //       father_name: "Saad",
      //       grNo: "112233",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10048,
      //       name: "Ahmed",
      //       father_name: "Sultan",
      //       grNo: "1011",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678518247915-575510498.png",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10047,
      //       name: "Ahmed",
      //       father_name: "ALi",
      //       grNo: "999",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678464900590-606838574.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10040,
      //       name: "Ahmed",
      //       father_name: "ahmed",
      //       grNo: "1002",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-computer",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10080,
      //       name: "Ahmed",
      //       father_name: "Hamza",
      //       grNo: "7789",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10031,
      //       name: "ahmed",
      //       father_name: "hassan",
      //       grNo: "10101",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "REC-Economics",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10052,
      //       name: "Ahsan",
      //       father_name: "Javaid",
      //       grNo: "8090223",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10065,
      //       name: "Ahsan",
      //       father_name: "Javaid",
      //       grNo: "1239",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10059,
      //       name: "Ali",
      //       father_name: "Arshad",
      //       grNo: "2290",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10070,
      //       name: "Ali",
      //       father_name: "Ahmed",
      //       grNo: "12382",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 24,
      //       name: "ali",
      //       father_name: "ahmed",
      //       grNo: "99",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674048617128-49928410.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "Test Name 1-chemistry",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10150,
      //       name: "Anas",
      //       father_name: "Ali",
      //       grNo: "11225597",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10134,
      //       name: "Anas ",
      //       father_name: "Alam",
      //       grNo: "11225587",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10116,
      //       name: "Anas",
      //       father_name: "Alam",
      //       grNo: "11225577",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10097,
      //       name: "Arbaz Hameed",
      //       father_name: "Hameed",
      //       grNo: "11225568",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 25,
      //       name: "Areeb",
      //       father_name: "khan",
      //       grNo: "100",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674048912745-520508781.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10079,
      //       name: "Arqam",
      //       father_name: "Mohtashim",
      //       grNo: "7798",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10083,
      //       name: "Arshad",
      //       father_name: "Ali",
      //       grNo: "11223351",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10075,
      //       name: "Arshad",
      //       father_name: "Ahmed",
      //       grNo: "7700",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10093,
      //       name: "Arshad",
      //       father_name: "Ali",
      //       grNo: "11225566",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10091,
      //       name: "Arshad",
      //       father_name: "Ahmed",
      //       grNo: "11223358",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10058,
      //       name: "Arshad",
      //       father_name: "Ali",
      //       grNo: "12323",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10094,
      //       name: "Asad",
      //       father_name: "Ali",
      //       grNo: "11225567",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-chemistry",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 38,
      //       name: "asas",
      //       father_name: "asas",
      //       grNo: "8889",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "REC-Economics",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10115,
      //       name: "asd",
      //       father_name: "asdas",
      //       grNo: "11225576",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10125,
      //       name: "Asghar Ali",
      //       father_name: "Mehtab Ali",
      //       grNo: "11225581",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10118,
      //       name: "Athar",
      //       father_name: "Akbar",
      //       grNo: "11225579",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 19,
      //       name: "Ayesha",
      //       father_name: "Hussain",
      //       grNo: "94",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674048005511-73782646.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "XII-Sci-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 17,
      //       name: "ayesha",
      //       father_name: "ali",
      //       grNo: "6",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10071,
      //       name: "Basim",
      //       father_name: "-",
      //       grNo: "2266",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 31,
      //       name: "check",
      //       father_name: "chh",
      //       grNo: "9090",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels III-Girls",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10146,
      //       name: "Ebad",
      //       father_name: "Ali",
      //       grNo: "11225593",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10074,
      //       name: "Ebad",
      //       father_name: "Ali",
      //       grNo: "11223348",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10057,
      //       name: "eev",
      //       father_name: "rbb",
      //       grNo: "999000",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10076,
      //       name: "Farida",
      //       father_name: "Akbar",
      //       grNo: "11223349",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels III-computer",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10064,
      //       name: "Farooq",
      //       father_name: "-",
      //       grNo: "1234",
      //       picture:
      //         "https://cdn.cloudious.net/file-1679135871272-505990008.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10029,
      //       name: "hamamd",
      //       father_name: "sheikh",
      //       grNo: "889",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "X-chemistry",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 21,
      //       name: "hammad",
      //       father_name: "asif",
      //       grNo: "96",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674048201589-168471551.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "XII-Sci-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 26,
      //       name: "hamza",
      //       father_name: "wahab",
      //       grNo: "101",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674048985750-372532152.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 33,
      //       name: "hamza",
      //       father_name: "kahn",
      //       grNo: "90909",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "IX-chemistry",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 18,
      //       name: "haris",
      //       father_name: "hussain",
      //       grNo: "93",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674047930487-459019247.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10078,
      //       name: "Hasan",
      //       father_name: "Ali Akhtar",
      //       grNo: "11223350",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10028,
      //       name: "hasnain",
      //       father_name: "ahmed",
      //       grNo: "8899",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "X-chemistry",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 32,
      //       name: "hey",
      //       father_name: "hey",
      //       grNo: "99090",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels-Girls",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 39,
      //       name: "hhhh",
      //       father_name: "kkkk",
      //       grNo: "2211",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O Levels-Girls",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 28,
      //       name: "hussain",
      //       father_name: "ali",
      //       grNo: "105",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "V-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10041,
      //       name: "Inaara",
      //       father_name: "Ali",
      //       grNo: "500",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678357697633-343425404.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10039,
      //       name: "Inaara",
      //       father_name: "Ali",
      //       grNo: "402",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10147,
      //       name: "Inaara",
      //       father_name: "ALi",
      //       grNo: "11225594",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10090,
      //       name: "Inaara",
      //       father_name: "Ali",
      //       grNo: "11223357",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "REC-Girls",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10061,
      //       name: "junaid",
      //       father_name: "akram",
      //       grNo: "11223346",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "JR-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10126,
      //       name: "Kamran",
      //       father_name: "-",
      //       grNo: "11225582",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10155,
      //       name: "Kashif",
      //       father_name: "Shahab",
      //       grNo: "11225602",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "XI-Bio-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10142,
      //       name: "Khurram",
      //       father_name: "Tajammul Hussain",
      //       grNo: "11225589",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10117,
      //       name: "M Ali",
      //       father_name: "Akbar",
      //       grNo: "11225578",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10149,
      //       name: "Maryam ",
      //       father_name: "Ali",
      //       grNo: "11225596",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10148,
      //       name: "Maryam",
      //       father_name: "Ali",
      //       grNo: "11225595",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 27,
      //       name: "maryam",
      //       father_name: "khan",
      //       grNo: "102",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674049166969-449480113.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10145,
      //       name: "MnM",
      //       father_name: "NNN",
      //       grNo: "11225592",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10111,
      //       name: "Mohsin",
      //       father_name: "-",
      //       grNo: "11225573",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10164,
      //       name: "moiz",
      //       father_name: "Ahmed",
      //       grNo: "11225611",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10077,
      //       name: "Moosa",
      //       father_name: "Bashir",
      //       grNo: "7784",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10158,
      //       name: "Mr.",
      //       father_name: "Imran",
      //       grNo: "11225605",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10154,
      //       name: "Mrs.Mudassiir",
      //       father_name: "-",
      //       grNo: "11225601",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10153,
      //       name: "Mudassir",
      //       father_name: "Ahmed",
      //       grNo: "11225600",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10037,
      //       name: "Muhamamd",
      //       father_name: "Aqeel",
      //       grNo: "301",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678085006942-236340581.png",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10038,
      //       name: "Muhammad",
      //       father_name: "Athar",
      //       grNo: "400",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678094351350-637549624.png",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10063,
      //       name: "Muhammad ",
      //       father_name: "Waqas",
      //       grNo: "12321",
      //       picture:
      //         "https://cdn.cloudious.net/file-1679473042101-208778501.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10045,
      //       name: "Muhammad",
      //       father_name: "Zakria",
      //       grNo: "805",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678458958240-70218220.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10069,
      //       name: "Muhammad",
      //       father_name: "Athar",
      //       grNo: "5566",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10081,
      //       name: "Muhammad",
      //       father_name: "Amjad",
      //       grNo: "9922",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10084,
      //       name: "Muhammad",
      //       father_name: "ANwar",
      //       grNo: "11223352",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10102,
      //       name: "Muhammad Ali",
      //       father_name: "Akbar",
      //       grNo: "11225571",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10,
      //       name: "Muhammad Athar",
      //       father_name: "Muhammad Akbar",
      //       grNo: "2",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678527254541-249811057.png",
      //       date: "2024-02-06T00:00:00",
      //       status: "P",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10128,
      //       name: "Muhammad Farooque",
      //       father_name: "Muhammad Ishaque",
      //       grNo: "11225584",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 15,
      //       name: "Muhammad Hamza",
      //       father_name: "Iqbal",
      //       grNo: "4",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674047618109-737152242.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "P",
      //       cls: "O Levels-chemistry",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10044,
      //       name: "Muneeba",
      //       father_name: "Nawaz",
      //       grNo: "777",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "P",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10144,
      //       name: "Musab",
      //       father_name: "Ali",
      //       grNo: "11225591",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10127,
      //       name: "Mustafa Ahmed ",
      //       father_name: "Shoaib Ahmed",
      //       grNo: "11225583",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10135,
      //       name: "Muzammil",
      //       father_name: "Kamran",
      //       grNo: "11225588",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10101,
      //       name: "Nade Ali",
      //       father_name: "-",
      //       grNo: "11225570",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10056,
      //       name: "Naseeb",
      //       father_name: "-",
      //       grNo: "444",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10088,
      //       name: "New",
      //       father_name: "yser",
      //       grNo: "11223355",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10143,
      //       name: "NEw",
      //       father_name: "test",
      //       grNo: "11225590",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10046,
      //       name: "Noor",
      //       father_name: "Ullah",
      //       grNo: "115",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678463060564-694173059.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10034,
      //       name: "Sabeeha",
      //       father_name: "Sadiq",
      //       grNo: "112",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10163,
      //       name: "sadaf",
      //       father_name: "nadeem",
      //       grNo: "11225610",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "SR-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10089,
      //       name: "sadas",
      //       father_name: "dasd",
      //       grNo: "11223356",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "JR-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10131,
      //       name: "Saeed",
      //       father_name: "-",
      //       grNo: "11225586",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10043,
      //       name: "Sara",
      //       father_name: "Ali",
      //       grNo: "700",
      //       picture:
      //         "https://cdn.cloudious.net/file-1678365826877-513746049.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10099,
      //       name: "Sarmad",
      //       father_name: "-",
      //       grNo: "11225569",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 22,
      //       name: "sheroz",
      //       father_name: "Saeed",
      //       grNo: "97",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674048293663-885563134.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "XII-Sci-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10033,
      //       name: "Student Test",
      //       father_name: "Student Father",
      //       grNo: "12345",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "X-chemistry",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10160,
      //       name: "Sumair",
      //       father_name: "Muhammad Haneef",
      //       grNo: "11225607",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10157,
      //       name: "Syed Husnain",
      //       father_name: "Rizvi",
      //       grNo: "11225604",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10068,
      //       name: "Syed Zahid",
      //       father_name: "Hussain",
      //       grNo: "5522",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10156,
      //       name: "Taabish",
      //       father_name: "AHmed",
      //       grNo: "11225603",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10112,
      //       name: "Tariq",
      //       father_name: "Tahir shah",
      //       grNo: "11225574",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10087,
      //       name: "Test",
      //       father_name: "user",
      //       grNo: "11223354",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "JR-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10152,
      //       name: "Test",
      //       father_name: "-",
      //       grNo: "11225599",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 23,
      //       name: "usman",
      //       father_name: "khan",
      //       grNo: "98",
      //       picture:
      //         "https://cdn.cloudious.net/file-1674048415086-541314971.jpg",
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "XII-Sci-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10114,
      //       name: "Uzair",
      //       father_name: "Saghir Ahmed",
      //       grNo: "11225575",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10110,
      //       name: "Waqas",
      //       father_name: "Ali",
      //       grNo: "11225572",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10035,
      //       name: "Waqas",
      //       father_name: "Mushtaq",
      //       grNo: "200",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10151,
      //       name: "Zain",
      //       father_name: "Ahmed",
      //       grNo: "11225598",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VIII-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10072,
      //       name: "Zain",
      //       father_name: "Zain",
      //       grNo: "11223347",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "O - III-Boys",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      //   {
      //     std: {
      //       id: 10055,
      //       name: "ZIauddin",
      //       father_name: "-",
      //       grNo: "401",
      //       picture: null,
      //       date: "2024-02-06T00:00:00",
      //       status: "A",
      //       cls: "VI-Biology",
      //     },
      //     dues: 0,
      //     time: null,
      //   },
      // ];
      // console.log(this.allStudents);
    },
    async loadAllStaff() {
      var obj = {
        url:
          this.$store.state.domain +
          "Attendance/LoadAllStaff?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allStaff = await this.get(obj);
      // console.log(this.allStaff);

      this.dataLoading = false;
    },
    async syncAtt() {
      // console.log(this.syncData);
      if (this.syncData.length > 0) {
        this.syncing = true;
        let temp = [...this.syncData];
        this.syncData = [];
        this.$store.commit("setSyncList", []);

        let data = temp.map((el) => ({
          id: el.std.grNo,
          time: el.time,
        }));
        // console.log(data);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SyncAttendance?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&userID=" +
            this.$store.state.userData.userID,
          body: data,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });

        if (!status) {
          this.syncData = [...this.syncData, ...temp];
          this.$store.commit("setSyncList", this.syncData);
        }

        this.syncing = false;
      }
      if (this.staffData.length > 0) {
        this.syncing = true;
        let temp = [...this.staffData];
        this.staffData = [];
        this.$store.commit("setStaffSyncList", []);

        let data = temp.map((el) => ({
          id: el.id.toString(),
          time: el.time,
        }));
        // console.log(data);

        var status2 = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SyncAttendanceStaff?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&userID=" +
            this.$store.state.userData.userID,
          body: data,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });

        if (!status2) {
          this.staffData = [...this.staffData, ...temp];
          this.$store.commit("setStaffSyncList", this.staffData);
        }

        this.syncing = false;
      }
      //  else {
      //   this.$bvToast.toast("No record to sync", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-center",
      //   });
      // }
    },

    async loadSessions() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj);
      let res = this.sessions.find((el) => el.isCurrentSession);
      this.sessionID = res ? res.id : 0;
    },
    changeAtt(ind, val) {
      this.filteredAtt[ind].obj.status = val;
    },
    async save() {
      if (this.gridData.length != 0) {
        this.saving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SaveWeb?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.gridData,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.loadgrid();
        }
      }
    },
    openDetails() {
      this.showreport = true;
      this.report.type = "student";
      this.showClasses = true;
    },
    setType(val) {
      this.report.type = val;
      if (this.report.type == "student") this.showClasses = true;
      else this.showClasses = false;
    },

    setTime() {
      console.log(this.timePicker);
      console.log(this.datepickr);
    },

    openMessage() {
      this.msgObj = {
        dt: "",
        clsIDs: [0],
        secIDs: [0],
        status: "P",
      };
      this.visibility2 = true;

      var elem = this.$refs["msg_class"];
      elem.state = undefined;
      // var elem = this.$refs["msg_section"];
      // elem.state = undefined;
      var elem = this.$refs["msg_date"];
      elem.state = undefined;
    },

    async saveMessage() {
      // console.log(this.msgObj);
      if (
        this.msgClass.length == 0 ||
        this.msgSection.length == 0 ||
        this.msgObj.dt == ""
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.msgClass[0] !== 0) {
          this.msgObj.clsIDs = this.msgClass;
        } else {
          this.msgObj.clsIDs = [];
        }
        if (this.msgSection[0] !== 0) {
          //console.log(this.secID);
          this.msgObj.secIDs = this.msgSection;
        } else {
          this.msgObj.secIDs = [];
        }
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SendMessage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.msgObj,
          message: "Message send successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility2 = false;
        }
      }
    },
    FillMsgSections() {
      // console.log(this.msgClass);
      if (this.msgClass[0] == 0 && this.msgClass.length !== 1) {
        this.msgClass = this.msgClass.filter((e) => e !== 0);
        // console.log(this.msgClass);
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.msgClass[this.msgClass.length - 1] !== 0 &&
        this.msgClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.msgClass[this.msgClass.length - 1] == 0) {
        this.msgClass = [0];
        // console.log(this.msgClass);
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      } else {
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      }
      // this.CheckClass();
    },
    checkMsgSections() {
      // console.log(this.msgSection);
      if (this.msgSection[0] == 0 && this.msgSection.length !== 1) {
        this.msgSection = this.msgSection.filter((e) => e !== 0);
        // console.log(this.msgSection);
      } else if (this.msgSection[this.msgSection.length - 1] == 0) {
        this.msgSection = [0];
        // console.log(this.msgSection);
      }
    },

    FillSections() {
      //   if (this.classID.includes(0) && this.classID.length == 2) {
      //     this.classID = this.classID.filter((e) => e !== 0);
      //     console.log(this.classID);
      //   }

      // console.log(this.classID);
      if (this.classID[0] == 0 && this.classID.length !== 1) {
        this.classID = this.classID.filter((e) => e !== 0);
        // console.log(this.classID);
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.classID[this.classID.length - 1] !== 0 &&
        this.classID.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.classID[this.classID.length - 1] == 0) {
        this.classID = [0];
        // console.log(this.classID);
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      } else {
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      }
      // this.CheckClass();
    },
    checkSections() {
      // console.log(this.secID);
      if (this.secID[0] == 0 && this.secID.length !== 1) {
        this.secID = this.secID.filter((e) => e !== 0);
        // console.log(this.secID);
      } else if (this.secID[this.secID.length - 1] == 0) {
        this.secID = [0];
        // console.log(this.secID);
      }
      // this.CheckSec();
    },
    CheckSec() {
      var elem = this.$refs["section"];
      if (this.secID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    FillRepSections() {
      // console.log(this.repClass);
      if (this.repClass[0] == 0 && this.repClass.length !== 1) {
        this.repClass = this.repClass.filter((e) => e !== 0);
        // console.log(this.repClass);
        this.classes.forEach((elem) => {
          if (this.repClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.repSecOptions.push(el));
            } else {
              // console.log("null");
              this.repSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.repClass[this.repClass.length - 1] !== 0 &&
        this.repClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.repClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.repSecOptions.push(el));
            } else {
              // console.log("null");
              this.repSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.repClass[this.repClass.length - 1] == 0) {
        this.repClass = [0];
        // console.log(this.repClass);
        this.repSecOptions = [{ section: "All Sections", id: 0 }];
        this.repSection = [0];
      } else {
        this.repSecOptions = [{ section: "All Sections", id: 0 }];
        this.repSection = [0];
      }
      // this.CheckClass();
    },
    checkRepSections() {
      // console.log(this.repSection);
      if (this.repSection[0] == 0 && this.repSection.length !== 1) {
        this.repSection = this.repSection.filter((e) => e !== 0);
        // console.log(this.repSection);
      } else if (this.repSection[this.repSection.length - 1] == 0) {
        this.repSection = [0];
        // console.log(this.repSection);
      }
    },
    setRepDate(sd, dateStr) {
      // console.log(sd, dateStr);
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[0];
          } else {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[1];
          }
        } else {
          this.repObj.dtFrom = dateStr;
          this.repObj.dtTo = null;
        }
      } else {
        this.repObj.dtFrom = null;
        this.repObj.dtTo = null;
      }
      // console.log(this.repObj);
    },
    setStatus() {
      if (this.repObj.status[this.repObj.status.length - 1] == "") {
        this.repObj.status = [""];
      } else {
        this.repObj.status = this.repObj.status.filter((el) => el != "");
      }
      // console.log(this.repObj.status);
    },
    openReport() {
      const fd = new Date();
      const firstDate = `${fd.getFullYear()}-${(fd.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-01`;

      const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
      const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

      // console.log(firstDate, lastDate);

      this.repObj = {
        type: "",
        dtFrom: firstDate,
        dtTo: lastDate,
        clsIDs: [0],
        secIDs: [0],
        status: [""],
      };
      this.repDate = `${firstDate} to ${lastDate}`;

      this.visibility3 = true;

      var elem = this.$refs["rep_class"];
      elem.state = undefined;
      // var elem = this.$refs["rep_section"];
      // elem.state = undefined;
      var elem = this.$refs["rep_date"];
      elem.state = undefined;
    },

    async getReport() {
      // console.log(this.repObj);
      if (
        this.repClass.length == 0 ||
        this.repSection.length == 0 ||
        this.repObj.status.length == 0 ||
        !this.repObj.dtFrom ||
        !this.repObj.dtTo
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }
        let status = "";
        if (this.repObj.status[0] !== "") {
          status = this.repObj.status;
        }
        this.request = true;

        var url =
          `https://${this.reportDomain}.myskool.app/Attendance/Show?type=student&dtFrom=` +
          this.repObj.dtFrom +
          "&dtTo=" +
          this.repObj.dtTo +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&cls=" +
          this.repObj.clsIDs +
          "&sec=" +
          this.repObj.secIDs +
          "&status=" +
          status;

        window.open(url, "_blank");
        this.request = false;
        // if (status) {
        //   this.visibility3 = false;
        // }
      }
    },
    async getSheet() {
      if (this.repClass.length == 0 || this.repSection.length == 0) {
        return this.$bvToast.toast("Please select class and section", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }

        this.sheetLoading = true;
        if (this.$store.state.currentBranch.organizationType == "coaching") {
          var url =
            `https://${this.reportDomain}.myskool.app/List/Show?type=attendance&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.repObj.clsIDs +
            "&sec=" +
            this.repObj.secIDs +
            "&session=" +
            this.sessionID +
            "&status=&mob=false";

          window.open(url, "_blank");
        } else {
          var url =
            `https://${this.reportDomain}.myskool.app/List/Show?type=attendance&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.repObj.clsIDs +
            "&sec=" +
            this.repObj.secIDs +
            "&status=&mob=false";

          window.open(url, "_blank");
        }
        this.sheetLoading = false;
      }
    },
    async loadgrid() {
      this.gridLoading = true;
      this.showGrid = true;
      if (this.classID[0] !== 0) {
        this.report.clsIDs = this.classID;
      } else {
        this.report.clsIDs = [];
      }
      if (this.secID[0] !== 0) {
        //console.log(this.secID);
        this.report.secIDs = this.secID;
      } else {
        this.report.secIDs = [];
      }
      // console.log(this.report);
      this.request = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Attendance/LoadAttendanceGrid?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: this.report,
        attendance: true,
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.gridData = status;
        this.sortData();
      }
      this.gridLoading = false;
    },
    async Edit(item) {
      this.attendance = item;
      // console.log(this.attendance);
      this.editAtt = true;
      this.datepickr = this.attendance.date.split("T")[0];
      this.timePicker = this.attendance.date.split("T")[1];

      var elem = this.$refs["account"];
      elem.state = undefined;
      var elem = this.$refs["balance"];
      elem.state = undefined;
    },
    async GetStudent() {
      if (this.studentID.length !== 0) {
        // sync setting
        if (this.syncObj && this.syncObj.valueBool) {
          this.studentLoading = true;
          if (
            this.studentID.toLowerCase().startsWith("e") ||
            this.studentID.endsWith("+")
          ) {
            let extractedNumber = this.studentID.replace(/[Ee+]/g, "");

            let stf = this.allStaff.find(
              (el) => el.id.toString() == extractedNumber
            );
            // console.log(stf);
            if (stf) {
              this.currentStaff = stf;
              let date = new Date();
              let day = new Date().toJSON().split("T")[0];
              let time = date.toTimeString().split(" ")[0];
              this.currentStaff.time = day + "T" + time;
              this.currentStaff.isStaff = true; // for recent
              this.items.unshift(this.currentStaff);
              this.staffData.push(this.currentStaff);
              this.$store.commit("setStaffSyncList", this.staffData);
            } else {
              this.currentStaff = null;
              this.$bvToast.toast("No staff found.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
            this.currentStd = {
              std: {
                picture: null,
                cls: null,
                grNo: null,
                name: null,
              },
              dues: null,
            };
          } else {
            let extractedNumber = this.studentID
              .match(/\d+/)[0]
              .replace(/^0+/, "");
            // console.log(extractedNumber);
            let res = this.allStudents.find(
              (el) => el.std.grNo == extractedNumber
            );
            // console.log(res);
            if (res) {
              this.currentStd = res;
              let date = new Date();
              let day = new Date().toJSON().split("T")[0];
              let time = date.toTimeString().split(" ")[0];
              this.currentStd.time = day + "T" + time;
              // console.log(this.currentStd.time);
              this.items.unshift(this.currentStd);
              this.syncData.push(this.currentStd);
              this.$store.commit("setSyncList", this.syncData);
            } else {
              this.currentStd = {
                std: {
                  picture: null,
                  cls: null,
                  grNo: null,
                  name: null,
                },
                dues: null,
              };
              this.$bvToast.toast("No student found.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
            this.currentStaff = null;
          }
          this.studentLoading = false;

          this.studentID = "";
          if (this.settings.autoHide) {
            setTimeout(() => {
              this.currentStd = {
                std: {
                  picture: null,
                  cls: null,
                  grNo: null,
                  name: null,
                },
                dues: null,
              };
              this.studentID = "";
            }, this.settings.hideAfterSeconds * 1000);
          }
        }
        // general
        else {
          this.studentLoading = true;
          var obj = {
            url:
              this.$store.state.domain +
              "Attendance/LoadStudentData?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&gr=" +
              this.studentID +
              "&userID=" +
              this.$store.state.userData.userID,
            token: this.$store.state.userData.token,
          };
          this.currentStd = await this.get(obj);
          // console.log("res:", this.currentStd);
          this.studentLoading = false;

          if (this.currentStd == "No data found.") {
            this.currentStd = {
              std: {
                picture: null,
                cls: null,
                grNo: null,
                name: null,
              },
              dues: null,
            };
            return this.$bvToast.toast("No student found.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          } else if (this.currentStd == "Student's profile is not active.") {
            this.currentStd = {
              std: {
                picture: null,
                cls: null,
                grNo: null,
                name: null,
              },
              dues: null,
            };
            return this.$bvToast.toast("Student's profile is not active.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          } else {
            // this.myObj = this.currentStd.std;
            // console.log("obj:", this.myObj);

            this.items.unshift(this.currentStd);
            // console.log(this.items);
          }
          this.studentID = "";
          if (this.settings.autoHide) {
            setTimeout(() => {
              this.currentStd = {
                std: {
                  picture: null,
                  cls: null,
                  grNo: null,
                  name: null,
                },
                dues: null,
              };
              this.studentID = "";
            }, this.settings.hideAfterSeconds * 1000);
          }
        }
      }
    },
    CheckAccount() {
      var elem = this.$refs["account"];
      if (this.myObj.account == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckBalance() {
      var elem = this.$refs["balance"];
      var re = /^[0-9]+$/;
      if (re.test(this.myObj.balance) && this.myObj.balance !== 0) {
        this.myObj.balance = parseInt(this.myObj.balance);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    hideAtt() {
      this.editAtt = false;
    },

    showSmart() {
      this.visibility4 = true;
    },
    async saveSmart() {
      if (
        this.smartObj.gr.trim() == "" ||
        this.smartObj.date == "" ||
        this.smartObj.status == ""
      ) {
        this.$bvToast.toast("Please enter the details properly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.smartObj.gr = this.smartObj.gr.replace(/\n|,/g, " ");
        // console.log(this.smartObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SmartAttendance?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.smartObj,
          message: "Attendance saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility4 = false;
        }
      }
    },

    showSettings() {
      this.visibility = true;
    },
    async LoadSettings() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "AttendanceSettings/LoadData?campusID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      // this.dataLoading = false;
      if (result == null) {
        this.settings = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          notifyArrival: false,
          notifyDeparture: false,
          showDues: false,
          showRecent: false,
          autoHide: false,
          hideAfterSeconds: 5,
          attendanceMode: "",
        };
      } else {
        this.settings = result;
      }
      // console.log("set", this.settings);
    },
    async LoadClasses() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      // console.log("class", this.classes);
      this.classes.forEach((el) => {
        this.classesOptions.push(el.cls);
      });
    },
    async LoadSections() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sections?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sections = await this.get(obj);
      // console.log("sections", this.sections);
    },
    async saveSettings() {
      this.request = true;
      let x = parseInt(this.settings.hideAfterSeconds);
      this.settings.hideAfterSeconds = !x ? 5 : x;

      var status = await this.post({
        url:
          this.$store.state.domain +
          "AttendanceSettings/Save?db=" +
          this.$store.state.userData.db,
        body: this.settings,
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.visibility = false;
        this.LoadSettings();
      }
    },
    async saveAttendance() {
      this.request = true;
      // this.attendance.obj.date = this.datepickr + this.timePicker;
      this.attendance.obj.date = new Date().toJSON();
      var status = await this.put({
        url:
          this.$store.state.domain +
          "Attendance/id=" +
          this.attendance.obj.id +
          "?db=" +
          this.$store.state.userData.db,
        body: this.attendance.obj,
        message: "Attendance updated successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.editAtt = false;
        this.loadgrid();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card-body {
  padding: 0.85rem;
}
@keyframes myfade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.set-picSize {
  width: 180px !important;
  height: 180px !important;
}

.set-border {
  border: 4px solid var(--danger) !important;
}
</style>
